import React from "react";

function Privacy() {
    return (
        <div className="lg:w-6/12 md:w-9/12 m-auto text-stone-400 font-['Montserrat'] leading-7 text-lg p-4 lg:p-0 md:p-0">
            <div>
                <h1 className="my-8 text-6xl font-bold bg-gradient-to-r from-blue-600 via-green-500 to-indigo-400 inline-block text-transparent bg-clip-text">
                    TrioTech Privacy Policy
                </h1>
                <h3 className="text-4xl my-12">
                    TrioTech agrees to keep all client information confidential. The client agrees not to disclose any
                    confidential information about TrioTech's processes, methodologies, or trade secrets.
                </h3>
            </div>
        </div>
    );
}

export default Privacy;
