import React from "react";
import "./index.css";
import Hero from "./components/Hero";
import WhoAreWe from "./components/WhoAreWe";
import Clients from "./components/Clients";
import WhatWeDo from "./components/WhatWeDo";
import Footer from "./components/Footer";
import { Routes, Route } from "react-router-dom";
import Press from "./pages/Press";
import InPersonEvents from "./pages/InPersonEvents";
import ContentProduction from "./pages/ContentProduction";
import BrandRecognition from "./pages/BrandRecognition";
import ContentSyndication from "./pages/ContentSyndication";
import SocialManagement from "./pages/SocialManagement";
import Pratham from "./pages/Pratham";
import SauravJain from "./pages/SauravJain";
import About from "./pages/About";
import Team from "./pages/Team";
import TermsAndConditions from "./pages/TermsAndConditions";
import Privacy from "./pages/Privacy";
import Scroll from "./components/Scroll";
import Pricing from "./components/Pricing";

function App() {
    return (
        <Routes>
            <Route
                path="/"
                element={
                    <div>
                        <Hero />
                        {/* <WhoAreWe />
                        <Clients />
                        <WhatWeDo />
                        <Footer /> */}
                        <Scroll />
                        <Pricing />
                        <Footer />
                    </div>
                }
            />
            {/* <Route
                path="/press"
                element={
                    <>
                        <Press />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/in-person-events"
                element={
                    <>
                        <InPersonEvents />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/content-production"
                element={
                    <>
                        <ContentProduction />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/brand-recognition"
                element={
                    <>
                        <BrandRecognition />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/content-syndication"
                element={
                    <>
                        <ContentSyndication />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/social-management"
                element={
                    <>
                        <SocialManagement />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/pratham"
                element={
                    <>
                        <Pratham />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/saurav-jain"
                element={
                    <>
                        <SauravJain />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/about"
                element={
                    <>
                        <About />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/team"
                element={
                    <>
                        <Team />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/terms-and-conditions"
                element={
                    <>
                        <TermsAndConditions />
                        <Footer />
                    </>
                }
            />
            <Route
                path="/privacy"
                element={
                    <>
                        <Privacy />
                        <Footer />
                    </>
                }
            /> */}
        </Routes>
    );
}

export default App;
