import React from "react";
import Navbar from "./Navbar";
import rapidLogo from "../assets/rapid-logo.png";
import clickUpLogo from "../assets/clickup-logo.png";
import tabnineLogo from "../assets/tabnine-logo.png";
import novuLogo from "../assets/novu-logo.png";
import tenWebLogo from "../assets/10-web.png";
import mongodbLogo from "../assets/mongodb-logo.png";
import harperdbLogo from "../assets/harperdb-logo.png";
import nevoDavid from "../assets/nevo.jpeg";
import daniGrant from "../assets/dani.jpeg";
function Hero() {
    return (
        <>
            <Navbar />
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 pb-16 pt-20 text-center lg:pt-32">
                <h1 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-tight text-slate-900 sm:text-7xl">
                    Find the{" "}
                    <span className="whitespace-nowrap text-blue-600">
                        <span>perfect audience</span>
                    </span>{" "}
                    for your startup success.
                </h1>
                <p className="mx-auto mt-6 max-w-2xl text-xl tracking-tight text-black font-['Montserrat']">
                    Product builders excel at their craft, we excel at crafting social presence, tech content, and
                    connecting them with the right audience.
                </p>
                <div className="mt-10 flex justify-center gap-x-6">
                    <a
                        className="group inline-flex items-center justify-center rounded-full py-4 px-8 text-lg font-semibold focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 bg-slate-900 text-white hover:bg-slate-700 hover:text-slate-100 active:bg-slate-800 active:text-slate-300 focus-visible:outline-slate-900"
                        variant="solid"
                        color="slate"
                        href="https://cal.com/prathkum14/30min"
                    >
                        Schedule a call
                    </a>
                </div>
                {/* <div className="mt-36 lg:mt-44">
                    <p className="font-display text-base text-slate-900">
                        We have helped the following ventures so far.
                    </p>
                    <ul
                        role="list"
                        className="mt-8 flex items-center justify-center gap-x-8 sm:flex-col sm:gap-x-0 sm:gap-y-10 xl:flex-row xl:gap-x-12 xl:gap-y-0"
                    >
                        <li>
                            <ul
                                role="list"
                                className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
                            >
                                <li className="flex">
                                    <img
                                        alt="Transistor"
                                        loading="lazy"
                                        width="158"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        style={{ color: "transparent" }}
                                        src={rapidLogo}
                                    />
                                </li>
                                <li className="flex">
                                    <img
                                        alt="Tuple"
                                        loading="lazy"
                                        width="105"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        style={{ color: "transparent" }}
                                        src={clickUpLogo}
                                    />
                                </li>
                                <li className="flex">
                                    <img
                                        alt="StaticKit"
                                        loading="lazy"
                                        width="127"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        style={{ color: "transparent" }}
                                        src={tabnineLogo}
                                    />
                                </li>
                            </ul>
                        </li>
                        <li>
                            <ul
                                role="list"
                                className="flex flex-col items-center gap-y-8 sm:flex-row sm:gap-x-12 sm:gap-y-0"
                            >
                                <li className="flex">
                                    <img
                                        alt="Mirage"
                                        loading="lazy"
                                        width="138"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        style={{ color: "transparent" }}
                                        src={novuLogo}
                                    />
                                </li>
                                <li className="flex">
                                    <img
                                        alt="Laravel"
                                        loading="lazy"
                                        width="136"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        style={{ color: "transparent" }}
                                        src={harperdbLogo}
                                    />
                                </li>
                                <li className="flex">
                                    <img
                                        alt="Statamic"
                                        loading="lazy"
                                        width="147"
                                        height="48"
                                        decoding="async"
                                        data-nimg="1"
                                        style={{ color: "transparent" }}
                                        src={tenWebLogo}
                                    />
                                </li>
                            </ul>
                        </li>
                    </ul>
                </div> */}
                <div className="mt-36 lg:mt-44">
                    <p className="text-black text-2xl font-bold">We do all!</p>
                    <div class="container mx-auto px-4 sm:px-6 lg:px-8 mt-8">
                        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                            <div class="bg-sky-500 rounded-lg shadow-lg p-6 text-center text-white">
                                <h2 class="text-2xl font-bold mb-4">Social Runs</h2>
                                <p>Engage in social runs with our community and stay active.</p>
                            </div>
                            <div class="bg-rose-500 rounded-lg shadow-lg p-6 text-center text-white">
                                <h2 class="text-2xl font-bold mb-4">Technical Writing</h2>
                                <p>Produce high-quality technical documentation and content.</p>
                            </div>
                            <div class="bg-cyan-500 rounded-lg shadow-lg p-6 text-center text-white">
                                <h2 class="text-2xl font-bold mb-4">Event Management</h2>
                                <p>Organize and manage events for your community or organization.</p>
                            </div>
                            <div class="bg-lime-500 rounded-lg shadow-lg p-6 text-center text-white">
                                <h2 class="text-2xl font-bold mb-4">Launch Optimization</h2>
                                <p>Optimize your product launch strategies for maximum impact.</p>
                            </div>
                            <div class="bg-amber-500 rounded-lg shadow-lg p-6 text-center text-white">
                                <h2 class="text-2xl font-bold mb-4">Developer Advocacy</h2>
                                <p>Advocate for developers and promote your technology solutions.</p>
                            </div>
                            <div class="bg-indigo-500 rounded-lg shadow-lg p-6 text-center text-white">
                                <h2 class="text-2xl font-bold mb-4">Community Building</h2>
                                <p>Build and nurture a strong and engaged community around your brand.</p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <p className="font-display text-slate-900 mt-24 text-xl font-bold">
                    People are talking about us. We are Grateful!
                </p>
                <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 text-slate-700 text-left mt-6">
                    <div className="grid gap-4 h-fit">
                        <div className="p-8 rounded-xl shadow-lg">
                            <p>
                                I have been working with Pratham and his team for almost two years. He is a professional
                                and a king of social media, growing his account from nothing to more than 400k
                                followers. I have run many campaigns through his X account and got a lot of value for
                                all my clients. Highly recommended.
                            </p>
                            <div className="flex items-center gap-4 mt-6">
                                <div className="rounded-full w-12 h-12 bg-black overflow-hidden">
                                    <img src={nevoDavid} />
                                </div>
                                <div className="flex flex-col tracking-wider">
                                    <label className="font-bold">Nevo David</label>
                                    <label className="font-normal text-sm">CEO and Founder at Gitroom</label>
                                </div>
                            </div>
                        </div>
                        <div className="p-8 rounded-xl shadow-lg">
                            <p>
                                We love working with Pratham. It's so cool how positively he is building a dev
                                following. Everything he shares is high quality, practical tips that can really help
                                developers. Plus, he's so great to work with, super responsive and reliable and awesome.
                            </p>
                            <div className="flex items-center gap-4 mt-6">
                                <div className="rounded-full w-12 h-12 bg-black overflow-hidden">
                                    <img src={daniGrant} />
                                </div>
                                <div className="flex flex-col tracking-wider">
                                    <label className="font-bold">Dani Grant</label>
                                    <label className="font-normal text-sm">CEO and Founder at Jam.dev</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-4 h-fit">
                        <div className="p-8 rounded-xl shadow-lg">
                            <p>
                                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam voluptatum aut totam
                                tempore adipisci quod? Rerum, tempore cum.
                            </p>
                            <div className="flex items-center gap-4 mt-6">
                                <div className="rounded-full w-12 h-12 bg-black overflow-hidden">
                                    <img src="https://rairaksa.github.io/assets/img/rai.jpg" />
                                </div>
                                <div className="flex flex-col tracking-wider">
                                    <label className="font-bold">3</label>
                                    <label className="font-normal text-sm">Fullstack PHP Developer</label>
                                </div>
                            </div>
                        </div>
                        <div className="p-8 rounded-xl shadow-lg">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Esse qui mollitia praesentium
                                tenetur blanditiis iure atque repudiandae recusandae! Modi id necessitatibus itaque
                                deleniti, vero nesciunt temporibus tempore laborum cumque similique! Ullam, fugit.
                                Quidem ut aut est odio reiciendis ipsam obcaecati nulla maiores repellat. Dolore,
                                recusandae?
                            </p>
                            <div className="flex items-center gap-4 mt-6">
                                <div className="rounded-full w-12 h-12 bg-black overflow-hidden">
                                    <img src="https://rairaksa.github.io/assets/img/rai.jpg" />
                                </div>
                                <div className="flex flex-col tracking-wider">
                                    <label className="font-bold">4</label>
                                    <label className="font-normal text-sm">Fullstack PHP Developer</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="grid gap-4 h-fit">
                        <div className="p-8 rounded-xl shadow-lg">
                            <p>
                                Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus fuga possimus quisquam
                                aspernatur modi natus officiis illum perferendis aliquam. Deserunt, iusto alias? Cumque
                                animi perferendis ex placeat! Sunt ipsum totam suscipit officiis libero vero illum.
                            </p>
                            <div className="flex items-center gap-4 mt-6">
                                <div className="rounded-full w-12 h-12 bg-black overflow-hidden">
                                    <img src="https://rairaksa.github.io/assets/img/rai.jpg" />
                                </div>
                                <div className="flex flex-col tracking-wider">
                                    <label className="font-bold">5</label>
                                    <label className="font-normal text-sm">Fullstack PHP Developer</label>
                                </div>
                            </div>
                        </div>
                        <div className="p-8 rounded-xl shadow-lg">
                            <p>
                                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequatur dignissimos
                                molestias officiis in cum ut vero fuga corporis iure! Accusamus, velit quidem omnis
                                nostrum ex quis enim, molestias ipsa unde ad aliquam dolor quibusdam in, vel eum est
                                totam atque maxime temporibus? Provident, enim optio hic laudantium tempore minima
                                placeat est alias itaque illum ipsa modi?
                            </p>
                            <div className="flex items-center gap-4 mt-6">
                                <div className="rounded-full w-12 h-12 bg-black overflow-hidden">
                                    <img src="https://rairaksa.github.io/assets/img/rai.jpg" />
                                </div>
                                <div className="flex flex-col tracking-wider">
                                    <label className="font-bold">6</label>
                                    <label className="font-normal text-sm">Fullstack PHP Developer</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </>
    );
}

export default Hero;
