import React from "react";

function Footer() {
    const date = new Date();
    const year = date.getFullYear();

    return (
        <>
            <footer className="text-stone-500">
                <div className="mx-auto max-w-screen-xl px-4 py-8 sm:px-6 lg:px-8">
                    {/* <div className="mt-8 grid grid-cols-2 gap-8 lg:mt-0 lg:grid-cols-5 lg:gap-y-16">
                        <div className="col-span-2 sm:col-span-1">
                            <p className="font-medium">Services</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a
                                        href="https://triotech.dev/in-person-events"
                                        className="transition hover:opacity-75"
                                    >
                                        In-person Events
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://triotech.dev/content-production"
                                        className="transition hover:opacity-75"
                                    >
                                        Content Production
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://triotech.dev/brand-recognition"
                                        className="transition hover:opacity-75"
                                    >
                                        Brand Recognition
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://triotech.dev/content-syndication"
                                        className="transition hover:opacity-75"
                                    >
                                        Content Syndication
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://triotech.dev/social-management"
                                        className="transition hover:opacity-75"
                                    >
                                        Social Management
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <p className="font-medium">Company</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="https://triotech.dev/about" className="transition hover:opacity-75">
                                        About
                                    </a>
                                </li>
                                <li>
                                    <a href="https://triotech.dev/team" className="transition hover:opacity-75">
                                        Meet the Team
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <p className="font-medium">Helpful Links</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a
                                        href="https://forms.gle/nmyV2ovzBjLAXdZLA"
                                        className="transition hover:opacity-75"
                                    >
                                        Get in Touch
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="transition hover:opacity-75">
                                        Proof of Work
                                    </a>
                                </li>
                                <li>
                                    <a href="#" className="transition hover:opacity-75">
                                        Success Stories
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <p className="font-medium">Founders</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="https://triotech.dev/pratham" className="transition hover:opacity-75">
                                        Pratham
                                    </a>
                                </li>
                                <li>
                                    <a href="https://triotech.dev/saurav-jain" className="transition hover:opacity-75">
                                        Saurav Jain
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="col-span-2 sm:col-span-1">
                            <p className="font-medium">Downloads</p>
                            <ul className="mt-6 space-y-4 text-sm">
                                <li>
                                    <a href="https://triotech.dev/press" className="transition hover:opacity-75">
                                        Press Kit
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://forms.gle/nmyV2ovzBjLAXdZLA"
                                        className="transition hover:opacity-75"
                                    >
                                        Sample Deck
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <ul className="col-span-2 flex justify-start gap-6 lg:col-span-5 lg:justify-end">
                            <li>
                                <a
                                    href="https://twitter.com/triotechdev"
                                    rel="noreferrer"
                                    target="_blank"
                                    className="transition hover:opacity-75"
                                >
                                    <span className="sr-only">X</span>
                                    <svg
                                        className="h-6 w-6"
                                        fill="#ffffff"
                                        xmlns="http://www.w3.org/2000/svg"
                                        height="1em"
                                        viewBox="0 0 512 512"
                                    >
                                        <path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" />
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div> */}
                    <div className="mt-8 pt-8 border-0 border-t-2 border border-gray-200 ">
                        <div className="sm:flex sm:justify-between">
                            <p className="text-xs">&copy; {year}. TrioTech. All rights reserved.</p>

                            {/* <ul className="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end">
                                <li>
                                    <a
                                        href="https://triotech.dev/terms-and-conditions"
                                        className="transition hover:opacity-75"
                                    >
                                        Terms & Conditions
                                    </a>
                                </li>
                                <li>
                                    <a href="https://triotech.dev/privacy" className="transition hover:opacity-75">
                                        Privacy Policy
                                    </a>
                                </li>
                            </ul> */}
                        </div>
                    </div>
                </div>
            </footer>
        </>
    );
}

export default Footer;
