import React from "react";
import { useState, useEffect } from "react";
import logo from "../assets/logo.png";

function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false);

    function toggleMenu() {
        setMenuOpen(!menuOpen);
    }

    function closeMenuOnScroll() {
        if (menuOpen) {
            setMenuOpen(false);
        }
    }

    // Add a scroll event listener to close the menu
    useEffect(() => {
        window.addEventListener("scroll", closeMenuOnScroll);

        return () => {
            window.removeEventListener("scroll", closeMenuOnScroll);
        };
    }, [menuOpen]);

    return (
        <header className="mt-4 mx-4 md:mx-auto lg:mx-auto relative lg:flex justify-between max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex justify-between items-center">
                <div>
                    <a href="https://triotech.dev" className="text-neutral-600 text-2xl">
                        <img src={logo} className="h-16 rounded-full" alt="TrioTech logo" />
                    </a>
                </div>
                {/* Hamburger menu icon for smaller screens */}
                {/* Terneory operator */}

                <div className="lg:hidden">
                    <input type="checkbox" className="hidden" id="menu-icon" onChange={toggleMenu} checked={menuOpen} />
                    <label htmlFor="menu-icon" className="text-slate-700 ease-in duration-300 cursor-pointer lg:hidden">
                        <svg
                            className="w-6 h-6 ease-in duration-300"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                className="ease-in duration-300"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="3"
                                d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
                            ></path>
                        </svg>
                    </label>
                </div>
            </div>
            <div
                className={
                    "absolute top-15 right-0 py-2 w-full transition-all bg-white shadow-xl rounded-xl " +
                    (menuOpen ? "opacity-1" : "invisible opacity-0")
                }
            >
                <a
                    href="https://twitter.com/messages/856155592578158592-1712179823542497280"
                    className="block text-slate-700	mb-5 m-auto text-xl text-center"
                >
                    Get in touch
                </a>
                <a
                    href="https://twitter.com/triotechdev"
                    className="block text-slate-700 mb-5 m-auto text-xl text-center"
                >
                    Follow us on 𝕏
                </a>
                <a
                    href="https://cal.com/prathkum14/30min"
                    className="block text-slate-700 m-auto mb-5 text-xl text-center"
                >
                    Schedule a call
                </a>
            </div>

            {/* Buttons for larger and medium screens */}
            <div className="hidden lg:flex space-x-6 items-center">
                <a
                    href="https://twitter.com/messages/856155592578158592-1712179823542497280"
                    className="text-slate-700 px-2"
                >
                    Get in touch
                </a>
                <a href="https://twitter.com/triotechdev" className="text-slate-700 px-2">
                    Follow us on 𝕏
                </a>
                <a href="https://cal.com/prathkum14/30min" className="text-slate-700 px-2">
                    Schedule a call
                </a>
            </div>
        </header>
    );
}

export default Navbar;
