import React from "react";
import codeSnippet from "../assets/code-snippet.png";
import productCycle from "../assets/product-cycle.png";

function Scroll() {
    return (
        <>
            <div className="bg-[#080a13] py-32 flex flex-row justify-center gap-4 flex-wrap">
                <div className="max-w-lg px-4">
                    <h1 className="font-display text-5xl font-bold tracking-tight sm:text-7xl text-white">
                        Reaching your ideal audience <span className="blur-[1px] line-through">is easy, right?</span>
                    </h1>
                    <p className="mt-6 text-xl tracking-tight font-['Montserrat'] text-[#b6b6b6] leading-relaxed">
                        We know you. You’re phenomenal at building products, but when it comes to connecting with your
                        audience, things can get a bit foggy. You’ve tried the traditional marketing playbooks, or maybe
                        you've spent hours drafting lengthy content strategies that nobody has the time to read. But
                        here’s the real kicker:{" "}
                        <span className="underline decoration-sky-500">
                            your potential customers are out there, and they’re missing out on your amazing product
                            because your message isn't reaching them.
                        </span>{" "}
                        This is starting to feel like a wild goose chase, isn’t it?
                    </p>
                    <h3 className="font-display text-5xl font-bold tracking-tight sm:text-7xl mt-8 text-white">
                        Break the mold, achieve more.
                    </h3>
                    <p className="mt-6 text-xl tracking-tight font-['Montserrat'] text-[#b6b6b6] leading-relaxed">
                        Picture launching your product with a strategy that captures attention instantly. Forget the
                        outdated, dusty approaches. It's time for something fresh and dynamic. With TrioTech,{" "}
                        <span className="underline decoration-sky-500">
                            you get a tailored, interactive experience that feels like driving a brand new sports car.
                        </span>{" "}
                        Our approach isn’t just another plan—it’s an adventure. From engaging communities and crafting
                        compelling stories to creating unforgettable launch events, we ensure your product shines. Watch
                        as your product grabs attention and your audience grows, leaving the old methods far behind 🔧.
                        Ready to transform your reach and soar? Let's make it happen 🦅.
                    </p>
                    <h3 className="font-display text-5xl font-bold tracking-tight sm:text-7xl mt-8 text-white">
                        Why TrioTech?
                    </h3>
                    <p className="mt-6 text-xl tracking-tight text-[#b6b6b6] font-['Montserrat'] leading-relaxed">
                        Because we’re not just another agency. We’re your allies in this journey. We dig deep,
                        understand your vision, and create a tailored plan that resonates. We turn your ideas into
                        compelling narratives and connect you with the people who matter.{" "}
                        <span className="underline decoration-sky-500">
                            With TrioTech, you’re not just heard—you’re remembered.
                        </span>{" "}
                        Stop wasting time with methods that don’t work. Let TrioTech help you find and connect with your
                        perfect audience. Together, we’ll make your startup shine like never before ✨.
                    </p>
                </div>
                <div className="relative flex flex-col gap-4">
                    <img className="sticky top-10" src={productCycle} width={600} alt="" />
                </div>
            </div>
        </>
    );
}

export default Scroll;
