import React from "react";
import "../../src/index.css";
function Pricing() {
    return (
        <>
            <div class="flex justify-center items-center pt-32">
                <div class="text-center">
                    <h1 className="mx-auto max-w-4xl font-display text-5xl font-bold tracking-tight text-slate-900 sm:text-7xl">
                        Pick the best{" "}
                        <span className="whitespace-nowrap text-blue-600">
                            <span>plan</span>
                        </span>{" "}
                        for you.
                    </h1>
                    <p className="mx-auto mt-6 max-w-2xl text-xl tracking-tight text-black font-['Montserrat']">
                        No hidden cost, no headache, just the right plan for your startup.
                    </p>
                    <div class="flex flex-col md:flex-row justify-center gap-6 mt-14 px-4 max-w-7xl items-stretch">
                        <div class="bg-gradient-to-r from-[#2bc0e4] to-[#b4b785] w-full md:w-1/3 text-white rounded-lg p-6 flex flex-col">
                            <h2 class="text-4xl font-semibold mb-4">Basic</h2>
                            <hr />
                            <p class="my-6 text-left uppercase">
                                Double down on your developer marketing efforts with your existing strategies.
                            </p>
                            <div class="text-4xl font-bold mb-4">
                                $6,500<span class="text-lg font-normal">/month</span>
                            </div>
                            <ul class="mb-6 space-y-2">
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> 5+ Influencers posting
                                </li>
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> Live in 24 hours
                                </li>
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> More...
                                </li>
                            </ul>
                            <div class="mt-auto">
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScctCudNdGxZ2Aun38eiAXCIsKw13ZlQ1bUATDFC9oUBoACVA/viewform?usp=sf_link"
                                    class="bg-slate-900 w-full text-white font-semibold py-2 rounded-full block"
                                >
                                    Choose Plan
                                </a>
                            </div>
                        </div>

                        <div class="bg-gradient-to-r from-[#d9a7c7] to-[#aea971] w-full md:w-1/3 text-white rounded-lg p-6 flex flex-col">
                            <h2 class="text-4xl font-semibold mb-4">Aggressive</h2>
                            <hr />
                            <p class="my-6 text-left uppercase">
                                We will develop strategies for you, from awareness to engagement to user conversion.
                            </p>
                            <div class="text-4xl font-bold mb-4">
                                $9,500<span class="text-lg font-normal">/month</span>
                            </div>
                            <ul class="mb-6 space-y-2">
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> 10+ Influencers posting
                                </li>
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> Live in 48 hours
                                </li>
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> More...
                                </li>
                            </ul>
                            <div class="mt-auto">
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScctCudNdGxZ2Aun38eiAXCIsKw13ZlQ1bUATDFC9oUBoACVA/viewform?usp=sf_link"
                                    class="bg-slate-900 w-full text-white font-semibold py-2 rounded-full block"
                                >
                                    Choose Plan
                                </a>
                            </div>
                        </div>

                        <div class="bg-gradient-to-r from-[#83a4d4] to-[#437e81] w-full md:w-1/3 text-white rounded-lg p-6 flex flex-col">
                            <h2 class="text-4xl font-semibold mb-4">Custom Price</h2>
                            <hr />
                            <p class="my-6 text-left uppercase">
                                Let us know your needs, and we will do our best to meet them.
                            </p>
                            <div class="text-4xl font-bold mb-4">
                                Your ask<span class="text-lg font-normal">/month</span>
                            </div>
                            <ul class="mb-6 space-y-2">
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> TBD
                                </li>
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> Live in 48 hours
                                </li>
                                <li class="flex items-center">
                                    <span class="mr-2">•</span> TBD
                                </li>
                            </ul>
                            <div class="mt-auto">
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScctCudNdGxZ2Aun38eiAXCIsKw13ZlQ1bUATDFC9oUBoACVA/viewform?usp=sf_link"
                                    class="bg-slate-900 w-full text-white font-semibold py-2 rounded-full block"
                                >
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Pricing;
